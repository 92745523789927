<template>
  <div>
    <el-button @click="detailShow">
      查看详情
    </el-button>
    <el-drawer
        size="40rem"
        destroy-on-close
        modal-append-to-body
        title="用户详情"
        :visible.sync="detailDrawer"
        :before-close="detailClose">
      <div class="section-box">
        <div class="section-title">
          <div class="title-tag"></div>
          基本信息
        </div>
        <div class="flex-def flex-cCenter" style="margin-top: .5rem;width: 100%;font-size: .8rem">
          <img :src="info.avatar | tomedia"
               style="width: 5rem;height: 5rem;border-radius: 10px;background-color: #f8f8f8">
          <div style="margin-left: 1rem;width: 100%">
            <div class="flex-def">
              <div>
                <div>{{ info.nickname }}</div>
                <div>{{ info.phone }}</div>
              </div>
              <div style="margin-left: 1rem">
                <div>用户ID:{{ info.id }}</div>
              </div>
            </div>
            <div style="margin-top: .5rem;width: 100%;font-size: 12px;line-height: 16px">
              <div>注册时间：{{ info.created_at | date }}</div>
            </div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="section-box">
        <div class="flex-def flex-cCenter flex-zBetween"
             style="margin-top: 1rem;width: 100%;font-size: .8rem;font-weight: 600">
          <div class="desc-item flex-def flex-zTopBottom flex-zCenter flex-cCenter">
            <div>{{ userLevel2Tag(principal.level) }}</div>
            <div style="margin-top: .5rem">当前主理人等级</div>
          </div>
          <div class="desc-item flex-def flex-zTopBottom flex-zCenter flex-cCenter">
            <div>{{ principal.card }}</div>
            <div style="margin-top: .5rem">剩余激活卡数量</div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="section-box">
        <div class="section-title">
          <div class="title-tag"></div>
          团队信息
        </div>
        <div class="flex-def flex-cCenter flex-zBetween"
             style="margin-top: 1rem;width: 100%;font-size: .8rem;font-weight: 600">
          <div @click="teamShow" class="team-item y-pointer">所属主理人服务团队</div>
          <div @click="cashLogShow" class="team-item y-pointer">提现记录</div>
          <div @click="awardLogShow" class="team-item y-pointer">佣金明细</div>
          <div @click="help_u_show=true" class="team-item y-pointer">直推第一二主理人</div>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="section-box">
        <div class="section-title">
          <div class="title-tag"></div>
          邀请人信息
        </div>
        <div class="flex-def flex-cCenter">
          <div v-if="from_user.id > 0">
            <div class="flex-def flex-cCenter" style="margin-top: 1rem;font-size: .8rem">
              <img :src="from_user.avatar | tomedia" style="border-radius: 2rem;height: 2rem;width: 2rem;">
              <div style="margin-left: 1rem">
                <div>{{ from_user.nickname }} <span style="color: red">（ID:{{ from_user.id }}）</span></div>
                <div>{{ from_user.phone }}</div>
              </div>
            </div>
            <div style="margin-top: 1rem;">
              <div>邀请人等级：{{ userLevel2Tag(from_u_principal.level) }}</div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-dialog title="所属主理人服务团队" :visible.sync="team_show" destroy-on-close>
      <el-table :data="team_list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="uid" label="用户编号"></el-table-column>
        <el-table-column :formatter="e=>e.is_head ? '服务奖励目标' : '成员'" label="团队关系"></el-table-column>
        <el-table-column prop="nickname" label="昵称"></el-table-column>
        <el-table-column prop="phone" label="电话"></el-table-column>
        <el-table-column prop="level" label="等级"></el-table-column>
        <el-table-column prop="from_uid" label="注册邀请人编号"></el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="team_total" :page-size="15" :current-page="team_page" @current-change="team_change">
          </el-pagination>
        </template>
      </el-table>
    </el-dialog>
    <el-dialog title="提现记录" :visible.sync="cash_log_show" destroy-on-close>
      <el-table :data="cash_log_list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="fee" label="提现金额"></el-table-column>
        <el-table-column prop="rate_fee" label="手续费"></el-table-column>
        <el-table-column prop="real_fee" label="实际到账"></el-table-column>
        <el-table-column prop="status" :formatter="e=>e.status === 2 ? '已打款' :  e.status === 1? '待打款' : '已驳回'"
                         label="提现状态"></el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="cash_log_total" :page-size="15" :current-page="cash_log_page"
                         @current-change="cash_log_change">
          </el-pagination>
        </template>
      </el-table>
    </el-dialog>
    <el-dialog title="佣金明细" :visible.sync="award_log_show" destroy-on-close>
      <div class="flex-def flex-cCenter" style="width: 100%;padding: .5rem">
        <el-date-picker v-model="award_log_filter_date"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
        <el-button-group>
          <el-button @click="awardLogSearch">搜索</el-button>
          <el-button @click="awardLogSearchReset">重置</el-button>
        </el-button-group>
      </div>
      <el-table :data="award_log_list">
        <el-table-column prop="id" width="80" label="编号"></el-table-column>
        <el-table-column prop="source_user.id" width="100" label="来源用户编号"></el-table-column>
        <el-table-column prop="source_user.nickname" label="来源用户昵称"></el-table-column>
        <el-table-column prop="fee" label="金额"></el-table-column>
        <el-table-column label="日期" width="180" show-overflow-tooltip>
          <template v-slot="s">{{ s.row.created_at | date }}</template>
        </el-table-column>
        <el-table-column prop="log" label="记录"></el-table-column>
        <el-table-column prop="order_id" label="关联订单编号"></el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="award_log_total" :page-size="10" :current-page="award_log_page"
                         @current-change="award_log_change">
          </el-pagination>
        </template>
      </el-table>
    </el-dialog>
    <el-dialog title="直推第一二主理人" :visible.sync="help_u_show" destroy-on-close>
      <div v-for="(item,key) in help_user" :key="key">
        <div class="flex-def">
          <div class="flex-def flex-cCenter" style="font-size: .8rem">
            <img :src="item.avatar | tomedia" style="border-radius: 2rem;height: 2rem;width: 2rem;">
            <div style="margin-left: 1rem">
              <div>{{ item.nickname }} <span style="color: red">（ID:{{ item.id }}）</span></div>
              <div>{{ item.phone }}</div>
            </div>
          </div>
          <div style="margin-left: 1rem;">
            <div>等级：{{ item.level }}</div>
          </div>
        </div>
        <el-divider v-if="key == 0"></el-divider>
      </div>

    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "principal-user-detail",
  props: {
    uid: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      detailDrawer: false,

      principal:{},
      from_u_principal:{},
      info: {},
      from_user: {},
      follow: false,
      help_user: [],


      // invite_link_list: [],
      // invite_link_page: 1,
      // invite_link_total: 0,
      // invite_link_show: false,

      team_list: [],
      team_page: 1,
      team_total: 0,
      team_show: false,

      cash_log_list: [],
      cash_log_page: 0,
      cash_log_total: 0,
      cash_log_show: false,
      award_log_search_date: [],
      award_log_filter_date: [],


      award_log_list: [],
      award_log_page: 0,
      award_log_total: 0,
      award_log_show: false,

      help_u_show: false,
    }
  },
  methods: {
    userLevel2Tag(level) {
      return level == 2 ? "金牌" : "普通"
    },
    award_log_change(e) {
      this.award_log_page = e;
      this.awardLogShow();
    },
    awardLogSearchReset(){
      this.award_log_filter_date = [];
      this.awardLogSearch();
    },
    awardLogSearch(){
      this.award_log_search_date = this.award_log_filter_date;
      this.award_log_list = [];
      this.award_log_total = 0;
      this.award_log_change(1);
    },
    awardLogShow() {
      let load = this.$loading({
        body: true,
        text: "请稍候..."
      })
      this.$u.api.user.awardLog({
        page: this.award_log_page,
        id: this.uid,
        range:this.award_log_search_date,
      }).then(res => {
        this.award_log_list = res.list;
        this.award_log_total = res.total;
        this.award_log_show = true;
        load.close();
      }).catch(() => {
        load.close();
      })
    },
    cash_log_change(e) {
      this.cash_log_page = e;
      this.cashLogShow();
    },
    cashLogShow() {
      let load = this.$loading({
        body: true,
        text: "请稍候..."
      })
      this.$u.api.user.cashLog({page: this.cash_log_page, id: this.uid}).then(res => {
        this.cash_log_list = res.list;
        this.cash_log_total = res.total;
        this.cash_log_show = true;
        load.close();
      }).catch(() => {
        load.close();
      })
    },
    team_change(e) {
      this.team_page = e;
      this.teamShow();
    },
    teamShow() {
      let load = this.$loading({
        body: true,
        text: "请稍候..."
      })
      this.$u.api.card.plugin.principal.userTeam({page: this.team_page, id: this.uid}).then(res => {
        this.team_list = res.list;
        this.team_total = res.total;
        this.team_show = true;
        load.close();
      }).catch(() => {
        load.close();
      })
    },
    // invite_link_page_change(e) {
    //   this.invite_link_page = e;
    //   this.inviteLinkShow();
    // },
    // inviteLinkShow() {
    //   let load = this.$loading({
    //     body: true,
    //     text: "请稍候..."
    //   })
    //   this.$u.api.card.plugin.wholesaler.userInviteLinkSearch({page: this.invite_link_page, id: this.uid}).then(res => {
    //     this.invite_link_list = res.list;
    //     this.invite_link_total = res.total;
    //     this.invite_link_show = true;
    //     load.close();
    //   }).catch(() => {
    //     load.close();
    //   })
    // },
    detailShow() {
      let load = this.$loading({
        body: true,
        text: "请稍候..."
      })
      this.$u.api.card.plugin.principal.userDetail({id: this.uid}).then(res => {
        this.info = res.info;
        this.from_user = res.from_user;
        this.from_u_principal = res.from_u_principal;
        this.principal = res.principal;
        this.help_user = res.help_user;
        this.detailDrawer = true;
        load.close();
      }).catch(() => {
        load.close();
      })
    },
    detailClose(done) {
      done();
    },
  }
}
</script>

<style scoped>
.section-box {
  position: relative;
  background-color: #FFFFFF;
  padding: 0 20px;
  font-size: .8rem
}

.section-title {
  display: flex;
  font-weight: 600;
  font-size: .9rem;
}

.title-tag {
  width: 5px;
  height: 20px;
  border-radius: 10px;
  background-color: #259b43;
  margin-right: 10px;
}

.red-btn {
  background-color: red;
  color: #FFFFFF;
  padding: .3rem 1rem;
  border-radius: .5rem;
  font-size: .5rem;
}

.team-item {
  background-color: #dbdedd;
  color: red;
  padding: .5rem 1rem;
  border-radius: 5px;
}
</style>